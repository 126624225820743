import React from "react";
import { SearchIcon } from "@primer/octicons-react";
import "./search.css";

// Search component...
const SearchInput = ({
  onChange = () => {},
  placeholder = "Search...",
}: {
  onChange?: (event: any) => void;
  placeholder?: string;
  value?: string;
}) => {
  return (
    <div className="app-search">
      <input
        className="app-search-input"
        type="text"
        onChange={onChange}
        placeholder={placeholder}
      />
      <SearchIcon />
    </div>
  );
};

export default SearchInput;
