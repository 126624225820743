import { XIcon } from "@primer/octicons-react";
import React, { CSSProperties } from "react";
import "./modal.css";

const Modal = ({
  title = "Modal Title",
  modalContent = "Modal Content",
  showModal = true,
  className = "",
  onClose = () => {},
  style = {},
}: {
  title?: string;
  modalContent?: string | JSX.Element;
  showModal?: boolean;
  onClose?: () => void;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <section
      style={{
        display: showModal ? "flex" : "none",
      }}
      className="app-modal-overlay">
      <section style={style} className={`app-modal ${className}`}>
        <header className="app-modal-header">
          <div className="app-modal-header-title">{title}</div>
          <div className="app-modal-header-close" onClick={onClose}>
            <XIcon size={24} />
          </div>
        </header>
        <section className="app-modal-content">{modalContent}</section>
      </section>
    </section>
  );
};

export default Modal;
