import React, { CSSProperties, useState } from "react";
import { SignOutIcon } from "@primer/octicons-react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { auth } from "../../modules";
import "./button.css";

// Logout Button...
const Logout = () => {
  // Router...
  const navigate = useNavigate();
  // Logout...
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch(() => {
        swal({
          title: "Failed to logout",
          text: "Please try again later.",
          icon: "warning",
          buttto: "Got it",
        });
      });
  };
  return (
    <div className="header-avatar" onClick={handleLogout}>
      <SignOutIcon size={24} />
    </div>
  );
};

const GroupedButtons = ({
  style = {},
  className = "",
  buttons = [
    {
      label: "Sales",
      type: "button",
    },
    {
      label: "Payouts",
      type: "button",
    },
  ],
  clickHandler = (label: string) => {
    console.log(label);
  },
}: {
  className?: string;
  buttons?: { label: string; type?: "button" | "button" | "reset" }[];
  clickHandler?: (label: string) => void;
  style?: CSSProperties;
}) => {
  // State...
  const [active, setActive] = useState(0);
  // Render...
  return (
    <div style={style} className={`app-btn-grouped ${className}`}>
      {buttons.map((item, index) => (
        <button
          type={item.type}
          key={`btn-${index}-${item.label}`}
          className={`app-btn-grouped-item ${
            active === index ? "app-btn-grouped-item-active" : ""
          }`}
          onClick={(event) => {
            event.preventDefault();
            setActive(index);
            clickHandler(item.label.trim().split(" ").join("-").toLowerCase());
          }}>
          {item.label}
        </button>
      ))}
    </div>
  );
};

export { Logout, GroupedButtons };
