import React, { useContext } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Navigate, Outlet } from "react-router-dom";
import { LineLoader } from "../components";
import { AuthContext } from "../contexts";

// Private route...
const PrivateRoute = () => {
  // Context...
  const { isAuthenticated, loading } = useContext(AuthContext);
  return isAuthenticated ? (
    <Outlet context={isAuthenticated} />
  ) : loading ? (
    <section className="loader">
      <LineLoader show={loading} />
      <RotatingLines strokeColor="#ddd" />
    </section>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
