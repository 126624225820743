import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { Modal } from "../../components";
import { AuthContext } from "../../contexts";
import { api, auth, validatePhoneNumber } from "../../modules";
import "./standalone.css";
import { TailSpin } from "react-loader-spinner";

const Standalone = () => {
  // Router hooks..
  const navigate = useNavigate();
  // Context...
  const { authToken } = useContext(AuthContext);
  const [valid, setValid] = useState(false);
  const [showModal, setShowModal] = useState(true);
  // Update title...
  useEffect(() => {
    document.title = "Standalone - Pay Express";
  }, []);

  // Handle modal close...
  const handleModalClose = () => {
    // Redirect to dashboard...
    navigate("/dashboard");
  };

  // Handle token processing...
  const handleTokenProcessing = (event: any) => {
    event.preventDefault();
    const { token, amount, phone } = event.target.elements;
    // Remove first zero from phone number...
    const phoneNumber = phone.value.replace(/^0+/, "");
    // Show loading...
    setShowModal(false);
    swal({
      content: (
        <div className="swal-loader-inner">
          <TailSpin height={30} width={30} />
          <p style={{ marginTop: 15 }}>Please Wait</p>
        </div>
      ),
      closeOnClickOutside: false,
      buttons: false,
    });
    api
      .processVoucherToken(authToken, {
        token: token.value,
        payout: Number(amount.value),
        phone: `+254${phoneNumber}`,
        teller: {
          email: auth.currentUser.email || "",
          name: auth.currentUser.displayName || "",
        },
      })
      .then((res) => {
        if (res.ok) {
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            closeOnClickOutside: false,
            button: "Got it",
          }).then(() => {
            // Show modal...
            setShowModal(true);
            // Clear form...
            amount.value = "";
            phone.value = "";
          });
        } else {
          swal({
            title: "Failed",
            text: res.message,
            icon: "warning",
            button: "Got it",
          }).then(() => {
            setShowModal(true);
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Failed",
          text: "Your payout request failed",
          icon: "error",
          button: "Got it",
        }).then(() => {
          setShowModal(true);
        });
      });
  };

  // Render...
  return (
    <section className="app-page">
      <section className="app-standalone-content">
        <Modal
          showModal={showModal}
          title="Process Token"
          onClose={handleModalClose}
          modalContent={
            <form
              autoComplete="off"
              autoCorrect="off"
              className="app-process-token-form"
              onSubmit={handleTokenProcessing}>
              <div className="app-modal-form-input">
                <label htmlFor="token">Token</label>
                <input
                  type="text"
                  id="token"
                  name="token"
                  minLength={14}
                  maxLength={14}
                  required
                />
              </div>
              <div className="app-modal-form-input">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  min={1}
                  required
                />
              </div>
              <div
                className="app-modal-form-input"
                onChange={(event: any) => {
                  const phoneNumber = validatePhoneNumber(event.target.value);
                  if (phoneNumber) {
                    setValid(true);
                  } else {
                    setValid(false);
                  }
                }}>
                <label htmlFor="phone">Phone</label>
                <input type="tel" id="phone" name="phone" required />
              </div>
              <button
                disabled={valid ? false : true}
                className="app-button app-button-primary app-button-disabled">
                Process
              </button>
            </form>
          }
        />
      </section>
    </section>
  );
};

export default Standalone;
