import * as React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import {
  DashboardPage,
  LoginPage,
  NotFoundPage,
  WalletPage,
  SettingsPage,
  SignupPage,
  StandalonePage,
  ResetPasswordPage,
} from "../pages";
import PrivateRoute from "./PrivateRoute";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/wallet" element={<WalletPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/standalone/pay-express" element={<StandalonePage />} />
      </Route>
      <Route path="/auth/signup" element={<SignupPage />} />
      <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
