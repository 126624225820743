import React from "react";
import { Rings } from "react-loader-spinner";
import "./loader.css";

const LineLoader = ({
  show = true,
  size = "2px",
  color = "#00bcd4",
  speed = 1,
}) => {
  return (
    <div style={show ? {} : { display: "none" }} className="app-loader-line">
      <div className="app-loader-line-container"></div>
    </div>
  );
};

const PageLoader = ({ show = true }) => {
  return (
    <section
      style={show ? { display: "flex" } : {}}
      className="app-loader-page">
      <Rings color="#ddd" ariaLabel="loading-indicator" />
    </section>
  );
};

export { LineLoader, PageLoader };
