// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  onAuthStateChanged,
  signOut,
  getIdToken,
  updateProfile,
  updateEmail,
  updatePhoneNumber,
  updatePassword,
  sendPasswordResetEmail,
  checkActionCode,
  applyActionCode,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBWtk64jAdzCCHE_O1gEEFTNLxfQP_Yd40",
  authDomain: "partners-vouchpal.firebaseapp.com",
  projectId: "partners-vouchpal",
  storageBucket: "partners-vouchpal.appspot.com",
  messagingSenderId: "365021721342",
  appId: "1:365021721342:web:4fad70b25cddad6e2a2baf",
};

// Initialize Firebase...
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Export the functions you need from the SDKs you need...
export {
  app,
  auth,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getIdToken,
  updateProfile,
  updateEmail,
  updatePhoneNumber,
  updatePassword,
  sendPasswordResetEmail,
  checkActionCode,
  applyActionCode,
};
