import React from "react";
import { XIcon } from "@primer/octicons-react";
import "./banner.css";

interface ThinBannerProps {
  message?: string;
  onClose?: () => void;
  top?: number;
  style?: React.CSSProperties;
  position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
  type?: "error" | "success" | "info" | "warning";
  show?: boolean;
  allowClose?: boolean;
}

const ThinBanner = ({
  message = "Hello World",
  top = 57,
  style = {},
  position = "sticky",
  type = "info",
  show = true,
  allowClose = true,
}: ThinBannerProps) => {
  return show ? (
    <section
      style={{
        ...style,
        position: position,
        top: `${top}px`,
      }}
      className={`app-thin-banner ${
        type === "info"
          ? ""
          : type === "warning"
          ? "app-thin-banner-warning"
          : "app-thin-banner-error"
      }`}>
      <div
        style={allowClose ? {} : { textAlign: "center" }}
        className="app-thin-banner-message">
        {message}
      </div>
      <div
        className="app-thin-banner-close"
        aria-disabled="true"
        style={
          allowClose
            ? {}
            : {
                display: "none",
              }
        }>
        <XIcon size={24} />
      </div>
    </section>
  ) : null;
};

export { ThinBanner };
