// Validate phone number format...
const validatePhoneNumber = (phoneNumber: string) => {
  const phone = phoneNumber.replace(/\D/g, "");
  const phoneNumberRegex =
    // eslint-disable-next-line
    /^\s*07[0-9]{8}$|^02[0-9]{8}$|^01[0-9]{8}$|^2547[0-9]{8}$|^2542[0-9]{8}$|^2541[0-9]{8}\s*$/;
  return phoneNumberRegex.test(phone);
};

// Validate email format...
const validateEmail = (email: string) => {
  const emailRegex =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

// Validate password format...
const validatePassword = (password: string) => {
  const passwordRegex =
    // eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  return passwordRegex.test(password);
};

// Validate an object has no empty fields...
const validateObject = (object: any) => {
  // Iterate an object...
  let showAlert = false;
  for (let key in object) {
    if (object.hasOwnProperty(key) && object[key] === "") {
      showAlert = true;
    }
  }
  return showAlert;
};

export {
  app,
  auth,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  getIdToken,
  updateProfile,
  updateEmail,
  updatePhoneNumber,
  updatePassword,
} from "./firebase";
export { api } from "./api";
export { validatePhoneNumber, validateEmail, validatePassword, validateObject };
