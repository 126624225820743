import React from "react";
import { Link } from "react-router-dom";
import "./not-found.css";

const NotFound = () => {
  document.title = `Scribble 404 - Not found`;
  return (
    <main className="main-404">
      <section className="section-404">
        <span>404</span>
        <p>The requested path could not be found</p>
        <Link to="/" className="back-404">
          home
        </Link>
      </section>
    </main>
  );
};

export default NotFound;
