import React, { useEffect, useState } from "react";
import "./pagination.css";

const Pagination = ({
  pages = 5,
  currentPage = 2,
  handlePagination = (page: number) => null,
}) => {
  // State..
  const [pageItems, setPageItems] = useState([]);
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(false);

  // Get page items...
  useEffect(() => {
    // Generate page items...
    const list = Array.from(Array(pages).keys());
    // Show prev and next buttons...
    if (pages <= 5) {
      setShowNext(false);
      setShowPrev(false);
    } else {
      setShowNext(true);
      setShowPrev(true);
    }
    // Set page items...
    if (pages < 6) {
      setPageItems(list);
    } else {
      if (currentPage <= 3) {
        setPageItems(list.slice(0, 5));
      } else if (currentPage >= pages - 2) {
        setPageItems(list.slice(pages - 5, pages));
      } else {
        setPageItems(list.slice(currentPage - 3, currentPage + 2));
      }
    }
    // Disable prev button...
    if (currentPage === 1) {
      setShowPrev(false);
    }
    if (currentPage === pages) {
      setShowNext(false);
    }
  }, [pages, currentPage]);

  // Handle next page...
  const handleNext = () => {
    if (currentPage < pages) {
      handlePagination(currentPage + 1);
    } else {
      handlePagination(currentPage);
    }
  };

  // Handle previous page...
  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePagination(currentPage - 1);
    } else {
      handlePagination(1);
    }
  };

  // Render...
  return (
    <div className="app-table-pagination">
      <button
        disabled={!showPrev}
        onClick={handlePrevious}
        className="app-table-pagination-prev-btn app-pagination-btn">
        Prev
      </button>
      <div className="app-table-pagination-btns">
        {pageItems.map((item) => (
          <button
            key={item + 1}
            className={`app-table-pagination-btn-item ${
              item + 1 === currentPage
                ? "app-table-pagination-btn-item-selected"
                : ""
            }`}
            onClick={() => {
              handlePagination(item + 1);
            }}>
            {item + 1}
          </button>
        ))}
      </div>
      <button
        onClick={handleNext}
        disabled={!showNext}
        className="app-table-pagination-next-btn app-pagination-btn">
        Next
      </button>
    </div>
  );
};

export default Pagination;
