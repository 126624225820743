import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { TailSpin } from "react-loader-spinner";
import { api, validatePassword } from "../../../modules";

const ResetPassword = () => {
  // Router...
  const [searchParams] = useSearchParams({});
  const navigate = useNavigate();

  // Get params...
  useEffect(() => {
    // Get params...
    const mode = searchParams.get("mode");
    const actionCode = searchParams.get("oobCode");
    // Check if params are valid...
    if (mode === "resetPassword" && actionCode) {
      swal({
        title: "Reset Password",
        content: {
          element: "input",
          attributes: {
            placeholder: "New Password",
            type: "password",
          },
        },
        closeOnClickOutside: false,
        buttons: "Reset",
      }).then((password: string) => {
        if (validatePassword(password)) {
          swal({
            content: (
              <div className="swal-loader-inner">
                <TailSpin height={30} width={30} />
                <p style={{ marginTop: 15 }}>Please Wait</p>
              </div>
            ),
            closeOnClickOutside: false,
            buttons: false,
          });
          api
            .handleResetPassword(actionCode, password)
            .then((ok) => {
              if (ok) {
                swal({
                  title: "Success",
                  text: "Your password has been reset",
                  icon: "success",
                  closeOnClickOutside: false,
                  button: "Got it",
                }).then(() => {
                  navigate("/");
                });
              } else {
                swal({
                  title: "Sorry",
                  text: "Something went wrong",
                  icon: "warning",
                  closeOnClickOutside: false,
                  button: "Got it",
                }).then(() => {
                  navigate("/");
                });
              }
            })
            .catch((err) => {
              swal({
                title: "Sorry",
                text: err.message,
                icon: "warning",
                closeOnClickOutside: false,
                button: "Got it",
              }).then(() => {
                navigate("/");
              });
            });
        } else {
          swal({
            title: "Sorry",
            text: "Password is not valid",
            icon: "warning",
            closeOnClickOutside: false,
            button: "Update",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    } else {
      // Redirect...
      navigate("/");
    }
  }, [searchParams, navigate]);
  return <></>;
};

export default ResetPassword;
