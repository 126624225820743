import axios from "axios";
import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
} from "firebase/auth";
import { PartnerPayload } from "../models";
import { auth, createUserWithEmailAndPassword, signOut } from "./firebase";

// Config host api...
const hostname = window.location.hostname;
let hostApi = "https://dev-api.vouchpal.com";
switch (hostname) {
  case "localhost":
    hostApi = "https://dev-api.vouchpal.com";
    break;
  case "dev-partner.vouchpal.com":
    hostApi = "https://dev-api.vouchpal.com";
    break;
  case "staging-partner.vouchpal.com":
    hostApi = "https://staging-api.vouchpal.com";
    break;
  case "partner.vouchpal.com":
    hostApi = "https://dev-api.vouchpal.com";
    break;
  default:
    hostApi = "https://dev-api.vouchpal.com";
    break;
}

class Partner {
  // Properties...
  hostApi: string;
  constructor(hostApi: string) {
    this.hostApi = hostApi;
  }
  // Get categories...
  async getCategories() {
    try {
      const res = await axios.get(`${this.hostApi}/categories`);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Get countries...
  async getCountries() {
    try {
      const res = await axios.get(`${this.hostApi}/countries`);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Partner signup...
  async partnerSignup(data: PartnerPayload) {
    try {
      const res = await axios.post(`${this.hostApi}/auth/partners/`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Firebase signup...
  async firebaseSignup(data: { email: string; password: string }) {
    try {
      const userCred = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      if (userCred.user) {
        return userCred.user;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  // Send reset password email...
  async sendResetPasswordEmail(email: string) {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      throw error;
    }
  }

  // Handle reset password...
  async handleResetPassword(actionCode: string, newPassword: string) {
    try {
      // TODO: Show the reset screen with the user's email and ask the user for
      // the new password.
      const email = await verifyPasswordResetCode(auth, actionCode);
      if (email) {
        // Password reset has been confirmed and new password updated...
        await confirmPasswordReset(auth, actionCode, newPassword);
        return true;
      } else {
        // Invalid action code...
        return false;
      }
    } catch (error) {
      throw error;
    }
  }

  // Get partner...
  async getPartner(authToken: string) {
    try {
      const res = await axios.get(`${this.hostApi}/auth/partners/?token=true`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Get auth token...
  async getAuthToken(email: string) {
    try {
      const res = await axios.post(`${this.hostApi}/auth/auth-token/`, {
        email,
      });
      return res.data ? res.data : null;
    } catch (error) {
      throw error;
    }
  }

  // Check user...
  async checkUser(email: string) {
    try {
      return await this.getAuthToken(email);
    } catch (error) {
      throw error;
    }
  }

  // Get partner transactions...
  async getPartnerTransactions(
    short_id: string,
    authToken: string,
    currentPage = 1
  ) {
    try {
      const res = await axios.get(
        `${this.hostApi}/partners/${short_id}/transactions/?token=true&page=${currentPage}&s=10`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data && res.data.ok ? res.data.data : null;
    } catch (error) {
      throw error;
    }
  }

  // Search for transactions...
  async searchTransactions(
    short_id: string,
    authToken: string,
    search: string,
    currentPage = 1
  ) {
    try {
      if (search.length > 2) {
        const res = await axios.get(
          `${this.hostApi}/partners/${short_id}/transactions/?token=true&q=${search}&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        return res.data && res.data.ok ? res.data.data : null;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }

  // Get partner payout requests...
  async getPartnerPayoutRequests(short_id: string, authToken: string) {
    try {
      const res = await axios.get(
        `${this.hostApi}/partners/${short_id}/payout/?token=true&page=1&s=15`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data && res.data.ok ? res.data.data : null;
    } catch (error) {
      throw error;
    }
  }

  // Update partner...
  async updatePartner(short_id: string, authToken: string, data: any) {
    try {
      const res = await axios.patch(
        `${this.hostApi}/partners/${short_id}/?token=true`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Get partner wallet...
  async getPartnerWallet(short_id: string, authToken: string) {
    try {
      const res = await axios.get(
        `${this.hostApi}/partners/${short_id}/wallet/?token=true`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data && res.data.ok ? res.data.data : null;
    } catch (error) {
      throw error;
    }
  }

  // Process voucher token...
  async processVoucherToken(
    authToken: string,
    data: {
      token: string;
      payout: number;
      phone: string;
      teller: {
        name: string;
        email: string;
      };
    }
  ) {
    try {
      const res = await axios.post(`${this.hostApi}/redeem?token=true`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return res.data ? res.data : null;
    } catch (error) {
      throw error;
    }
  }

  // Verify voucher processing...
  async verifyVoucherProcessing(
    authToken: string,
    data: {
      ref: string;
    }
  ) {
    try {
      const res = await axios.post(
        `${this.hostApi}/redeem?token=true`,
        {
          verify: true,
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data ? res.data : null;
    } catch (error) {
      throw error;
    }
  }

  // Send a partner payout request...
  async requestPayout(
    short_id: string,
    authToken: string,
    data: {
      amount: number;
    }
  ) {
    try {
      const res = await axios.post(
        `${this.hostApi}/partners/${short_id}/payout/?token=true`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Add a user...
  async addUser(
    short_id: string,
    authToken: string,
    data: {
      email: string;
      role: string;
    }
  ) {
    try {
      const res = await axios.post(
        `${this.hostApi}/partners/${short_id}/users/?token=true`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Remove a user...
  async removeUser(
    short_id: string,
    authToken: string,
    data: {
      email: string;
      role: string;
    }
  ) {
    try {
      const res = await axios.delete(
        `${this.hostApi}/partners/${short_id}/users/?token=true`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data,
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  // Sign out...
  async signOut() {
    try {
      // Sign out...
      const res = await signOut(auth);
      // Clear local storage...
      localStorage.clear();
      return res;
    } catch (error) {
      throw error;
    }
  }
}

// Get instance...
const api = new Partner(hostApi);

// Export...
export { api };
