import React, { createContext, useState, FC, useEffect } from "react";
import { api, auth, onAuthStateChanged } from "../modules";
import { User, PartnerProfile } from "../models";

// Interface for the context...
interface AuthContextProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  user: User | null;
  hostApi: string;
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  partner: PartnerProfile;
  setPartner: (partner: any | PartnerProfile) => void;
  showSidebar: boolean;
  setShowSidebar: (showSidebar: boolean) => void;
}
interface AuthProviderProps {
  children: React.ReactNode;
}

// Auth context...
const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
} as AuthContextProps);

// Auth provider...
const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  // States...
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [hostApi, setHostApi] = useState("https://dev-api.vouchpal.com");
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [partner, setPartner] = useState<PartnerProfile>(null);
  const [showSidebar, setShowSidebar] = useState(false);

  // Setup host api...
  useEffect(() => {
    const hostname = window.location.hostname;
    switch (hostname) {
      case "localhost":
        setHostApi("https://dev-api.vouchpal.com");
        break;
      case "dev-partner.vouchpal.com":
        setHostApi("https://dev-api.vouchpal.com");
        break;
      case "staging-partner.vouchpal.com":
        setHostApi("https://staging-api.vouchpal.com");
        break;
      case "partner.vouchpal.com":
        setHostApi("https://dev-api.vouchpal.com");
        break;
      default:
        setHostApi("https://dev-api.vouchpal.com");
        break;
    }
  }, []);

  // Auth state...
  useEffect(() => {
    // Load auth state...
    setLoading(true);
    onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          // User is signed in...
          setUser(user);
          const token = localStorage.getItem("authToken")
            ? localStorage.getItem("authToken")
            : (await api.getAuthToken(user.email)).data.token;
          if (token) {
            // Save auth token to local storage...
            localStorage.setItem("authToken", token);
            // Set auth token...
            setAuthToken(token);
            // Get partner...
            api
              .getPartner(token)
              .then((partner) => {
                if (partner.ok) {
                  setPartner({
                    ...partner.data.partner,
                    sales: partner.data.sales,
                    admin: partner.data.admin,
                  });
                  setIsAuthenticated(true);
                  setLoading(false);
                } else {
                  setIsAuthenticated(false);
                  setLoading(false);
                  api.signOut();
                }
              })
              .catch((error) => {
                // Error...
                setIsAuthenticated(false);
                setLoading(false);
                api.signOut();
              });
          } else {
            setIsAuthenticated(false);
            api.signOut();
          }
        } else {
          // User is signed out
          setIsAuthenticated(false);
          setLoading(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
        setLoading(false);
      }
    });
  }, []);

  // Set authenticated...
  useEffect(() => {
    if (partner) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [partner]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        hostApi,
        authToken,
        setAuthToken,
        loading,
        setLoading,
        partner,
        setPartner,
        setIsAuthenticated,
        showSidebar,
        setShowSidebar,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
