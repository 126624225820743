import { ThreeBarsIcon } from "@primer/octicons-react";
import React, { useContext, CSSProperties } from "react";
import { AuthContext } from "../../contexts";
import { Logout } from "../button";
import "./header.css";

const Header = ({ style = {} }: { style?: CSSProperties }) => {
  // Context...
  const { setShowSidebar } = useContext(AuthContext);
  // Render...
  return (
    <header style={style} className="app-header">
      <span
        className="app-header-menu"
        onClick={(e) => {
          e.preventDefault();
          setShowSidebar(true);
        }}>
        <ThreeBarsIcon size={24} />
      </span>
      <div className="app-header-logo"></div>
      <div className="app-header-actions">
        <Logout />
      </div>
    </header>
  );
};

export default Header;
