import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { RotatingLines, TailSpin } from "react-loader-spinner";
import swal from "@sweetalert/with-react";
import { AuthContext } from "../../../contexts";
import {
  api,
  auth,
  signInWithEmailAndPassword,
  validateEmail,
} from "../../../modules";
import "./signin.css";

const Signin = () => {
  // Router...
  const navigate = useNavigate();
  // Context...
  const { isAuthenticated, loading, setLoading, setAuthToken, setPartner } =
    useContext(AuthContext);
  // State...
  const [authEmail, setAuthEmail] = useState("");

  // Set page title...
  useEffect(() => {
    document.title = "Vouchpal - Partner Signin";
  }, []);

  // Handle auth...
  const handleAuth = async (userAuth: { email: string; password: string }) => {
    try {
      swal({
        content: (
          <div className="swal-loader-inner">
            <TailSpin height={30} width={30} />
            <p style={{ marginTop: 15 }}>Authenticating...</p>
          </div>
        ),
        closeOnClickOutside: false,
        buttons: false,
      });
      // Authenticate user...
      const userCred = await signInWithEmailAndPassword(
        auth,
        userAuth.email,
        userAuth.password
      );
      // Check if user is authenticated...
      if (userCred.user) {
        // Get auth token...
        const tokenRes = await api.getAuthToken(userCred.user.email);
        if (tokenRes.ok) {
          // Save auth token to local storage...
          localStorage.setItem("authToken", tokenRes.data.token);
          // Set auth token...
          setAuthToken(tokenRes.data.token);
          // Get partner...
          const partner = await api.getPartner(tokenRes.data.token);
          if (partner.ok) {
            setLoading(false);
            setPartner({
              ...partner.data.partner,
              sales: partner.data.sales,
              admin: partner.data.admin,
            });
            // Close swal...
            swal.close();
            // Navigate to home...
            navigate("/");
          } else {
            // Close swal...
            swal.close();
            api.signOut();
          }
        } else {
          // Close swal...
          swal({
            title: "Sorry",
            text: tokenRes.message,
            icon: "warning",
            button: "Got it",
          }).then(() => {
            // Close swal...
            swal.close();
            // Sign out...
            api.signOut();
          });
        }
      } else {
        // Show error...
        swal({
          title: "Error",
          text: "Invalid email or password",
          icon: "error",
          button: "Got it",
        });
        // Sign out...
        api.signOut();
      }
    } catch (error) {
      throw error;
    }
  };

  // Handle login...
  const handleLogin = async (event: any) => {
    // Prevent default...
    event.preventDefault();
    // Get user input...
    const { email, password } = event.target.elements;
    try {
      // Set loading...
      const userAuth = {
        email: email.value,
        password: password.value,
      };
      // Set auth email...
      setAuthEmail(email.value);
      // Handle auth...
      await handleAuth(userAuth);
    } catch (error) {
      // Handle error...
      const message =
        error && error.code && error.code === "auth/user-not-found"
          ? "Account not found, do you want to create an account?"
          : error.code === "auth/wrong-password"
          ? "Your password is incorrect, please try again"
          : "Something went wrong. Please try again or contact support";
      const data =
        (error && error.code === "auth/wrong-password") ||
        (error && error.code === "auth/user-not-found")
          ? {
              title: "Sorry",
              text: message,
              icon: "warning",
              buttons: ["Cancel", "Sure"],
            }
          : {
              title: "Sorry",
              text: message,
              icon: "warning",
              button: "Got it",
            };
      swal(data).then((res: any) => {
        if (res && error.code === "auth/user-not-found") {
          navigate("/auth/signup/?email=" + email.value);
        } else {
          // Sign out...
          api.signOut();
        }
      });
      // Sign out...
      api.signOut();
    }
  };

  // Send reset password email...
  const handleReset = (email: string) => {
    swal({
      content: (
        <div className="swal-loader-inner">
          <TailSpin height={30} width={30} />
          <p style={{ marginTop: 15 }}>Please Wait</p>
        </div>
      ),
      closeOnClickOutside: false,
      buttons: false,
    });
    api
      .sendResetPasswordEmail(email)
      .then(() => {
        swal({
          title: "Success",
          text: "Check your email for a password reset link",
          icon: "success",
          closeOnClickOutside: false,
          button: "Got it",
        });
      })
      .catch((err) => {
        swal({
          title: "Sorry",
          text: err.message,
          icon: "warning",
          closeOnClickOutside: false,
          button: "Got it",
        });
      });
  };
  // Render...
  return isAuthenticated ? (
    <Navigate to="/dashboard" replace={true} />
  ) : loading ? (
    <section className="loader">
      <RotatingLines strokeColor="#ddd" />
    </section>
  ) : (
    <section className="app-auth-page">
      <section className="app-auth-content">
        <div className="app-auth-title">Partner Sign in</div>
        <form
          className="app-auth-form"
          onSubmit={handleLogin}
          autoComplete="off">
          <div className="app-auth-form-input-field">
            <label htmlFor="email">Email Address</label>
            <input
              name="email"
              type="text"
              required
              placeholder="Email Address"
              id="email"
              className="app-input"
            />
          </div>
          <div className="app-auth-form-input-field">
            <div className="app-auth-form-footer">
              <div className="app-auth-form-forgot-password">
                <label htmlFor="password">Password</label>
                <span
                  className="app-auth-form-forgot-label-text"
                  onClick={(event) => {
                    event.preventDefault();
                    // Send reset password email...
                    const isEmail = validateEmail(authEmail);
                    if (isEmail) {
                      handleReset(authEmail);
                    } else {
                      swal({
                        title: "Your Email",
                        content: {
                          element: "input",
                          attributes: {
                            placeholder: "e.g hey@vouchpal.com",
                            type: "email",
                            id: "email",
                          },
                        },
                        buttons: ["Cancel", "Send"],
                      }).then((email: string) => {
                        if (email && validateEmail(email)) {
                          handleReset(email);
                        }
                      });
                    }
                  }}>
                  Forgot Password
                </span>
              </div>{" "}
            </div>
            <input
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="on"
              id="password"
              required={true}
              className="app-input"
            />
          </div>
          <button type="submit" className="app-button app-auth-button">
            Sign in
          </button>
          <div className="app-auth-join-link">
            <span>New partner?</span> <Link to="/auth/signup">Sign up</Link>
          </div>
        </form>
        <div className="app-auth-footer"></div>
      </section>
    </section>
  );
};

export default Signin;
