import React, { useContext, useState } from "react";
import swal from "@sweetalert/with-react";
import {
  CreditCardIcon,
  GearIcon,
  HomeIcon,
  ReplyIcon,
  RocketIcon,
  SignOutIcon,
  UnverifiedIcon,
  VerifiedIcon,
} from "@primer/octicons-react";
import { NavLink } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { AuthContext } from "../../contexts";
import { api, auth, validatePhoneNumber } from "../../modules";
import Modal from "../modal";
import "./sidebar.css";

const Sidebar = () => {
  // Context...
  const { partner, authToken, showSidebar, setShowSidebar } =
    useContext(AuthContext);
  // State...
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [processPayout, setProcessPayout] = useState(false);
  const [verify, setVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [txRef, setTxRef] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    message: "",
    type: "",
    show: false,
  });
  const [payout, setPayout] = useState({
    amount: "1",
    token: "",
    phone: "",
  });

  // Handle token processing...
  const handleTokenProcessing = (event: any) => {
    event.preventDefault();
    const { token, amount, phone } = event.target.elements;
    // Remove first zero from phone number...
    const phoneNumber = phone.value.replace(/^0+/, "");
    // Show loading...
    setLoading(true);
    api
      .processVoucherToken(authToken, {
        token: token.value,
        payout: Number(amount.value),
        phone: `+254${phoneNumber}`,
        teller: {
          email: auth.currentUser.email || "",
          name: auth.currentUser.displayName || "",
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          if (res.code === "pay/success") {
            setAlertMessage({
              message: res.message,
              type: "success",
              show: true,
            });
            // Show modal...
            setShowPayoutModal(true);
          } else {
            setVerify(true);
            setAlertMessage({
              message: res.message,
              type: "warning",
              show: true,
            });
            setTxRef(res.data.ref);
          }
        } else {
          setAlertMessage({
            message: res.message,
            type: "error",
            show: true,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        swal({
          title: "Failed",
          text: "Your payout request failed",
          icon: "error",
          button: "Got it",
        }).then(() => {
          setShowPayoutModal(true);
        });
      });
  };

  // Render...
  return (
    <section
      style={showSidebar ? { display: "flex" } : {}}
      className="app-sidebar">
      <div className="app-sidebar-header">
        <div className="app-sidebar-header-avatar">
          <img
            className="app-sidebar-header-avatar-img"
            src="https://avatars0.githubusercontent.com/u/52709818?s=460&u=f9f8b8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8f8&v=4"
            alt="avatar"
          />
        </div>
        <div className="app-sidebar-header-info">
          <div className="app-sidebar-header-info-name">
            {partner.verified ? (
              <VerifiedIcon fill="#19a17a" />
            ) : (
              <UnverifiedIcon fill="57606a" />
            )}{" "}
            <span>{partner.name}</span>
          </div>
          <div className="app-sidebar-header-info-email">
            <span>{auth.currentUser?.email}</span>
          </div>
        </div>
      </div>
      <section className="app-sidebar-content">
        <div className="app-sidebar-content-item app-sidebar-content-active">
          <div
            className={
              partner.active
                ? "app-sidebar-content-item-active-dot"
                : "app-sidebar-content-item-active-dot app-sidebar-content-item-inactive "
            }></div>
          <div className="app-sidebar-content-item-text">
            {partner.active ? "Active" : "Inactive"}
          </div>
        </div>
        <NavLink
          onClick={() => setShowSidebar(false)}
          to="/dashboard"
          className={({ isActive }) => {
            return isActive
              ? "app-sidebar-content-item app-sidebar-content-item-active"
              : "app-sidebar-content-item";
          }}>
          <div className="app-sidebar-content-item-icon">
            <HomeIcon />
          </div>
          <div className="app-sidebar-content-item-text">Home</div>
        </NavLink>
        <div
          className="app-sidebar-content-item"
          onClick={() => setShowPayoutModal(true)}>
          <div className="app-sidebar-content-item-icon">
            <ReplyIcon />
          </div>
          <div className="app-sidebar-content-item-text">Process</div>
        </div>
        <NavLink
          style={{ display: "none" }}
          to={"/standalone/pay-express"}
          className="app-sidebar-content-item"
          onClick={() => {
            setShowPayoutModal(true);
            setShowSidebar(false);
          }}>
          <div className="app-sidebar-content-item-icon">
            <RocketIcon />
          </div>
          <div className="app-sidebar-content-item-text">Express</div>
        </NavLink>
        {partner.admin ? (
          <NavLink
            onClick={() => setShowSidebar(false)}
            to="/wallet"
            className={({ isActive }) => {
              return isActive
                ? "app-sidebar-content-item app-sidebar-content-item-active"
                : "app-sidebar-content-item";
            }}>
            <div className="app-sidebar-content-item-icon">
              <CreditCardIcon />
            </div>
            <div className="app-sidebar-content-item-text">Wallet</div>
          </NavLink>
        ) : null}
        <NavLink
          onClick={() => setShowSidebar(false)}
          to="/settings"
          className={({ isActive }) => {
            return isActive
              ? "app-sidebar-content-item app-sidebar-content-item-active"
              : "app-sidebar-content-item";
          }}>
          <div className="app-sidebar-content-item-icon">
            <GearIcon />
          </div>
          <div className="app-sidebar-content-item-text">Settings</div>
        </NavLink>
        <div
          className="app-sidebar-content-item"
          onClick={() => {
            api.signOut();
            setShowSidebar(false);
          }}>
          <div className="app-sidebar-content-item-icon">
            <SignOutIcon />
          </div>
          <div className="app-sidebar-content-item-text">Signout</div>
        </div>
      </section>
      <Modal
        title="Process Token"
        showModal={showPayoutModal}
        onClose={() => setShowPayoutModal(false)}
        modalContent={
          loading ? (
            <div className="swal-loader-inner">
              <TailSpin height={30} width={30} />
              <p style={{ marginTop: 15 }}>Please Wait</p>
            </div>
          ) : verify || alertMessage.show ? (
            alertMessage.show ? (
              <div
                className={`app-modal-alert-message ${
                  alertMessage.type === "success"
                    ? "app-modal-alert-message-success"
                    : alertMessage.type === "error"
                    ? "app-modal-alert-message-error"
                    : alertMessage.type === "warning"
                    ? "app-modal-alert-message-warning"
                    : ""
                }`}>
                <div className="app-modal-alert-message-text">
                  {alertMessage.message}
                </div>
                <div className="app-modal-form-verify-buttons">
                  <button
                    className="app-button app-button-primary"
                    onClick={() => {
                      setAlertMessage({
                        show: false,
                        message: "",
                        type: "",
                      });
                    }}>
                    Proceed
                  </button>
                </div>
              </div>
            ) : (
              <div className="app-modal-form-verify">
                <div className="app-modal-form-verify-title">
                  Confirm Transaction Approval
                </div>
                <div className="app-modal-form-verify-text">
                  Please confirm that Transaction was approved by the sender
                </div>
                <div className="app-modal-form-verify-buttons">
                  <button
                    className="app-button app-button-primary app-button-cancel"
                    onClick={() => setVerify(false)}>
                    Cancel
                  </button>
                  <button
                    className="app-button app-button-primary"
                    onClick={() => {
                      setLoading(true);
                      setVerify(true);
                      api
                        .verifyVoucherProcessing(authToken, {
                          ref: txRef,
                        })
                        .then((res) => {
                          if (res.ok) {
                            setLoading(false);
                            setAlertMessage({
                              show: true,
                              message: res.message,
                              type:
                                res.code === "pay/success"
                                  ? "success"
                                  : "warning",
                            });
                          } else {
                            setLoading(false);
                            setAlertMessage({
                              message: res.message,
                              type: "warning",
                              show: true,
                            });
                          }
                        });
                    }}>
                    Verify
                  </button>
                </div>
              </div>
            )
          ) : (
            <form
              autoComplete="off"
              autoCorrect="off"
              className="app-process-token-form"
              onSubmit={handleTokenProcessing}>
              <div className="app-modal-form-input">
                <label htmlFor="token">Token</label>
                <input
                  type="text"
                  id="token"
                  name="token"
                  minLength={12}
                  maxLength={14}
                  required
                  value={payout.token}
                  onChange={(event) => {
                    setPayout({ ...payout, token: event.target.value });
                  }}
                />
              </div>
              <div className="app-modal-form-input">
                <label htmlFor="amount">Amount</label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  min={1}
                  onChange={(event) => {
                    setPayout((prev) => {
                      return {
                        ...prev,
                        amount: event.target.value,
                      };
                    });
                  }}
                  required
                  value={payout.amount}
                />
              </div>
              <div className="app-modal-form-input">
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={(event: any) => {
                    const phoneNumber = validatePhoneNumber(event.target.value);
                    setPayout((prev) => {
                      return {
                        ...prev,
                        phone: event.target.value,
                      };
                    });
                    if (phoneNumber) {
                      setProcessPayout(true);
                    } else {
                      setProcessPayout(false);
                    }
                  }}
                  required
                  value={payout.phone}
                />
              </div>
              <button
                disabled={processPayout ? false : true}
                className="app-button app-button-primary app-button-disabled">
                Process
              </button>
            </form>
          )
        }
      />
    </section>
  );
};

export default Sidebar;
