import React, { useState } from "react";
import Pagination from "../pagination";
import SearchInput from "../search";
import "./table.css";

const Table = ({
  className = "",
  header = [],
  data = [],
  emptyText = "No data yet",
  pagination = true,
  paginationOptions = {
    pages: 1,
    currentPage: 1,
    handlePagination: (page: number) => page,
  },
  showCTAs = false,
  handleSearch = () => {},
}: {
  className?: string;
  header?: {
    label: string;
    className?: string;
    width?: string | number;
  }[];
  data?: React.ReactElement[];
  emptyText?: string;
  pagination?: boolean;
  paginationOptions?: {
    pages?: number;
    currentPage?: number;
    handlePagination?: (page: number) => void;
  };
  showCTAs?: boolean;
  handleSearch?: (search: string) => void;
}) => {
  // State...
  const [currentPage, setCurrentPage] = useState(1);
  // Render...
  return (
    <section>
      <section
        style={showCTAs ? {} : { display: "none" }}
        className="app-table-ctas">
        <SearchInput
          placeholder="Search by token..."
          onChange={(event) => {
            handleSearch(event.target.value);
          }}
        />
      </section>
      <table className={`app-table ${className}`}>
        <thead>
          <tr>
            {header.map((item, index) => (
              <th
                style={{
                  minWidth: item.width || "",
                }}
                key={index}>
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data}</tbody>
      </table>
      {data.length === 0 ? (
        <div className="app-table-empty">{emptyText}</div>
      ) : null}
      {pagination && paginationOptions.pages > 1 ? (
        <Pagination
          pages={paginationOptions.pages}
          currentPage={currentPage}
          handlePagination={(page) => {
            setCurrentPage(page);
            paginationOptions.handlePagination(page);
          }}
        />
      ) : null}
    </section>
  );
};

export default Table;
