import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import swal from "@sweetalert/with-react";
import { TailSpin } from "react-loader-spinner";
import { SyncIcon } from "@primer/octicons-react";
import {
  GroupedButtons,
  Header,
  LineLoader,
  PageLoader,
  Sidebar,
  Table,
} from "../../components";
import { AuthContext } from "../../contexts";
import { api } from "../../modules";
import "./dashboard.css";

const DashBoard = () => {
  // Context...
  const { partner, authToken } = useContext(AuthContext);
  // State...
  const [sales, setSales] = useState([]);
  const [payout, setPayout] = useState([]);
  const [fetchingTransactions, setFetchingTransactions] = useState(false);
  const [showSales, setShowSales] = useState(true);
  const [greeting, setGreeting] = useState("Good Morning");
  const [pages, setPages] = useState({
    sales: 1,
    payout: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [makeTableSticky, setMakeTableSticky] = useState(false);

  // Set page title...
  useEffect(() => {
    // Set page title...
    document.title = "Vouchpal - Dashboard";
    // Get greetings with moment js...
    const greetings = () => {
      const now = moment();
      const hour = now.hour();
      if (hour < 12) {
        return "Good Morning";
      } else if (hour < 18) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    };
    // Set greeting...
    setGreeting(greetings());
  }, []);

  // Get partner transactions...
  useEffect(() => {
    if (partner && authToken) {
      // Get sales...
      setFetchingTransactions(true);
      api
        .getPartnerTransactions(partner.short_id, authToken)
        .then((res) => {
          if (res) {
            setSales(res.sales);
            setPayout(res.payout);
            setPages(res.pages);
          }
          setFetchingTransactions(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchingTransactions(false);
        });
    } else {
      setFetchingTransactions(false);
    }
    // Cleanup...
    return () => {
      setSales([]);
      setPayout([]);
      setFetchingTransactions(false);
    };
  }, [partner, authToken]);

  // Handle scroll...
  const handleScroll = (e: any) => {
    e.preventDefault();
    const top = e.target.scrollTop;
    if (Number(top) > 82) {
      setMakeTableSticky(true);
    } else {
      setMakeTableSticky(false);
    }
  };

  // Handle pagination...
  const handlePagination = (page: number) => {
    // Get sales...
    setCurrentPage(page);
    setFetchingTransactions(true);
    if (partner && authToken) {
      api
        .getPartnerTransactions(partner.short_id, authToken, page)
        .then((res) => {
          if (res) {
            if (showSales) {
              setSales(res.sales);
            } else {
              setPayout(res.payout);
            }
          }
          setFetchingTransactions(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchingTransactions(false);
        });
    } else {
      setFetchingTransactions(false);
    }
  };

  // Fetching transactions...
  const fetchTransactions = () => {
    if (partner && authToken) {
      // Get sales...
      setFetchingTransactions(true);
      api
        .getPartnerTransactions(partner.short_id, authToken)
        .then((res) => {
          if (res) {
            setSales(res.sales);
            setPayout(res.payout);
            setPages(res.pages);
          }
          setFetchingTransactions(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchingTransactions(false);
        });
    } else {
      setFetchingTransactions(false);
    }
  };

  // Handle search...
  const handleSearch = (value: string) => {
    // Get sales...
    setFetchingTransactions(true);
    if (partner && authToken && value.length > 3) {
      api
        .searchTransactions(partner.short_id, authToken, value, currentPage)
        .then((res) => {
          if (res) {
            setSales(res.sales);
            setPayout(res.payout);
            setPages(res.pages);
          }
          setFetchingTransactions(false);
        })
        .catch((err) => {
          console.log(err);
          setFetchingTransactions(false);
        });
    } else {
      if (value === "") {
        fetchTransactions();
      } else {
        setFetchingTransactions(false);
      }
    }
  };

  // Render...
  return (
    <section className="app-page">
      <LineLoader show={fetchingTransactions} />
      <PageLoader show={fetchingTransactions} />
      <Sidebar />
      <section
        onScroll={handleScroll}
        className="app-page-content app-dashboard">
        <Header />
        <section className="app-dashboard-content">
          <div className="app-page-greeting">{greeting}</div>
          <h1 className="app-page-title">Dashboard</h1>
          <section className="app-dashboard-content-inner">
            <section className="app-dashboard-sales">
              <div className="app-dashboard-sales-card">
                <div className="app-dashboard-sales-card-title">
                  Total Sales (Ksh)
                </div>
                <div className="app-dashboard-sales-card-value">
                  {partner.sales.gross.toLocaleString()}
                </div>
              </div>
              <div className="app-dashboard-sales-card">
                <div className="app-dashboard-sales-card-title">
                  Net Income (Ksh)
                </div>
                <div className="app-dashboard-sales-card-value">
                  {partner.sales.net.toLocaleString()}
                </div>
              </div>
              <div className="app-dashboard-sales-card">
                <div className="app-dashboard-sales-card-title">
                  Sold Vouchers
                </div>
                <div className="app-dashboard-sales-card-value">
                  {partner.sales.count.toLocaleString()}
                </div>
              </div>
            </section>
            <section
              style={
                makeTableSticky
                  ? {
                      position: "sticky",
                      top: 50,
                      height: "calc(100vh - 75px)",
                      overflowY: "auto",
                      backgroundColor: "#fff",
                    }
                  : {}
              }>
              <section
                style={
                  makeTableSticky
                    ? {
                        position: "sticky",
                        top: 0,
                        background: "#fff",
                        zIndex: 3,
                        padding: "20px 0",
                        margin: "0 auto",
                      }
                    : {
                        marginBottom: 0,
                      }
                }
                className="app-dashboard-grouped-buttons">
                <GroupedButtons
                  clickHandler={(value) => {
                    if (value.toLocaleLowerCase() === "sales") {
                      setShowSales(true);
                    } else {
                      setShowSales(false);
                    }
                  }}
                />
                <button
                  className="app-button app-button-small"
                  onClick={(event) => {
                    event.preventDefault();
                    fetchTransactions();
                  }}>
                  <SyncIcon /> <span>Refresh</span>
                </button>
              </section>
              <section className="app-table-container">
                {showSales ? (
                  <Table
                    header={[
                      {
                        label: "Customer",
                        width: 150,
                      },

                      {
                        label: "Amount (Ksh)",
                        width: 100,
                      },
                      {
                        label: "Income (Ksh)",
                        width: 100,
                      },

                      {
                        label: "Charge (Ksh)",
                        width: 100,
                      },

                      {
                        label: "Date & Time",
                        width: 100,
                      },
                      {
                        label: "Payment",
                        width: 80,
                      },
                      {
                        label: "Type",
                        width: 80,
                      },
                    ]}
                    data={sales.map((sale) => (
                      <tr key={sale.id}>
                        <td className="app-dashboard-table-user">
                          <div className="app-dashboard-table-user-name">
                            {sale.phone}
                          </div>
                          <div className="app-dashboard-table-user-phone">
                            {sale.token}
                          </div>
                        </td>
                        <td className="app-dashboard-table-amount">
                          {sale.amount.toLocaleString()}
                        </td>
                        <td className="app-dashboard-table-income">
                          {sale.income.toLocaleString()}
                        </td>
                        <td className="app-dashboard-table-income">
                          {`${sale.amount - sale.income}`.toLocaleString()}
                        </td>

                        <td className="app-dashboard-table-date">
                          <div className="app-dashboard-table-date-date">
                            {sale.date_created.created_on}
                          </div>
                          <div className="app-dashboard-table-date-time">
                            {sale.date_created.time_since}
                          </div>
                        </td>
                        <td className="app-dashboard-table-payment-method">
                          {sale.payment.type.toLocaleLowerCase()}
                        </td>
                        <td className="app-dashboard-table-type">
                          <div className="app-dashboard-table-open">
                            {sale.token_type}
                          </div>
                        </td>
                      </tr>
                    ))}
                    emptyText={
                      fetchingTransactions
                        ? "Fetching transactions..."
                        : "No transactions yet"
                    }
                    pagination={true}
                    paginationOptions={{
                      pages: pages.sales,
                      currentPage: currentPage,
                      handlePagination: handlePagination,
                    }}
                    showCTAs={true}
                    handleSearch={handleSearch}
                  />
                ) : (
                  <Table
                    header={[
                      {
                        label: "Teller",
                        width: 150,
                      },
                      {
                        label: "Token",
                        width: 150,
                      },
                      {
                        label: "Amount (Ksh)",
                        width: 150,
                      },
                      {
                        label: "Redeemer",
                        width: 100,
                      },
                      {
                        label: "Date & Time",
                        width: 175,
                      },
                      {
                        label: "Status",
                        width: 80,
                      },
                      {
                        label: "Process",
                        width: 80,
                      },
                    ]}
                    data={payout.map((payout) => (
                      <tr key={payout.id}>
                        <td className="app-dashboard-table-user">
                          <div className="app-dashboard-table-user-name">
                            {payout.teller.name}
                          </div>
                          <div className="app-dashboard-table-user-email">
                            {payout.teller.email}
                          </div>
                        </td>
                        <td className="app-dashboard-table-user">
                          <div className="app-dashboard-table-user-name">
                            {payout.token}
                          </div>
                          <div className="app-dashboard-table-user-token">
                            {payout.payer}
                          </div>
                        </td>

                        <td className="app-dashboard-table-amount">
                          {payout.amount.toLocaleString()}
                        </td>
                        <td className="app-dashboard-table-redeemer">
                          {payout.payee}
                        </td>
                        <td className="app-dashboard-table-date">
                          <div className="app-dashboard-table-date-date">
                            {payout.date_created.created_on}
                          </div>
                          <div className="app-dashboard-table-date-time">
                            {payout.date_created.time_since}
                          </div>
                        </td>
                        <td className="app-dashboard-table-status">
                          <span
                            className={
                              payout.status === "successful"
                                ? "app-label app-label-success"
                                : payout.status === "failed" ||
                                  payout.status === "rejected"
                                ? "app-label app-label-error"
                                : "app-label app-label-warning"
                            }>
                            <span>{payout.status}</span>
                          </span>
                        </td>
                        <td className="app-dashboard-table-status">
                          <button
                            className="app-button app-button-small"
                            style={
                              payout.status === "rejected"
                                ? { display: "none" }
                                : { width: "100%" }
                            }
                            disabled={
                              payout.verified
                                ? true
                                : payout.status === "successful"
                                ? false
                                : true
                            }
                            onClick={(event) => {
                              event.preventDefault();
                              // Set loading...
                              swal({
                                content: (
                                  <div className="swal-loader-inner">
                                    <TailSpin height={30} width={30} />
                                    <p style={{ marginTop: 15 }}>Please Wait</p>
                                  </div>
                                ),
                                closeOnClickOutside: false,
                                buttons: false,
                              });
                              api
                                .verifyVoucherProcessing(authToken, {
                                  ref: payout.ref,
                                })
                                .then((res) => {
                                  if (res.ok) {
                                    if (res.code === "pay/success") {
                                      swal({
                                        title: "Success",
                                        text: res.message,
                                        icon: "success",
                                        closeOnClickOutside: false,
                                        button: "Got It",
                                      });
                                    } else {
                                      swal({
                                        title: "Sorry",
                                        text: res.message,
                                        icon: "warning",
                                        closeOnClickOutside: false,
                                        button: "Got It",
                                      });
                                    }
                                  } else {
                                    swal({
                                      title: "Sorry",
                                      text: res.message,
                                      icon: "warning",
                                      closeOnClickOutside: false,
                                      button: "Got It",
                                    });
                                  }
                                })
                                .catch((err) => {
                                  swal({
                                    title: "Sorry",
                                    text: err.message
                                      ? err.message
                                      : "Sorry, an error occurred. Please try again later or contact support",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    button: "Got It",
                                  });
                                });
                            }}>
                            {payout.verified && payout.status === "successful"
                              ? "Verified"
                              : "Verify"}
                          </button>
                        </td>
                      </tr>
                    ))}
                    emptyText={
                      fetchingTransactions
                        ? "Fetching data..."
                        : "No transactions yet"
                    }
                    pagination={true}
                    paginationOptions={{
                      pages: pages.payout,
                      currentPage: currentPage,
                      handlePagination: handlePagination,
                    }}
                    showCTAs={true}
                    handleSearch={handleSearch}
                  />
                )}
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default DashBoard;
