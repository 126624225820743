import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { api, validateEmail, validatePassword } from "../../../modules";
import { Link } from "react-router-dom";
import swal from "@sweetalert/with-react";
import "./signup.css";

const SignupPage = () => {
  // Router...
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // State..
  const [partnerInfo, setPartnerInfo] = useState({
    name: "",
    avatar: "",
    description: "",
    email: "",
    pro: false,
    uid: "",
    password: "",
    country: {
      slug: "ke",
      name: "Kenya",
      code: "+254",
    },
    address: {
      street: "",
      city: "",
      state: "",
    },
    category: {
      id: "",
      name: "",
      sub_categories: [],
    },
    contact: {
      email: "",
      phone: "",
    },
  });
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    id: "0",
    sub_categories: [],
  });
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    slug: "ke",
    code: "+254",
  });
  const [step, setStep] = useState(1);
  const [inputErrors, setInputErrors] = useState({
    password: {
      show: false,
      value: "",
    },
    email: {
      show: false,
      value: "",
    },
    contactEmail: {
      show: false,
      value: "",
    },
    phone: {
      show: false,
      value: "",
    },
  });

  // Set page title...
  useEffect(() => {
    document.title = "Vouchpal - Partner Signup";
    // Get email from url...
    if (searchParams.get("email")) {
      setPartnerInfo((prev) => {
        return {
          ...prev,
          email: searchParams.get("email"),
        };
      });
      // Clear search params...
      setSearchParams("");
    }
  }, [searchParams, setSearchParams]);

  // Check views...
  useEffect(() => {
    if (partnerInfo.email === "" && partnerInfo.password === "") {
      setStep(1);
    }
  }, [partnerInfo]);

  // Get Data...
  useEffect(() => {
    // Get categories...
    api
      .getCategories()
      .then((res) => {
        if (res.ok && res.data.length > 0) {
          setCategories(res.data);
          setSelectedCategory(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // Get Countries...
    api
      .getCountries()
      .then((res) => {
        if (res.ok && res.data.length > 0) {
          setCountries(res.data);
          setSelectedCountry(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // Cleanup...
    return () => {
      setCategories([]);
      setCountries([]);
    };
  }, []);

  // Get data from local storage...
  useEffect(() => {
    const partnerInfo = localStorage.getItem("partnerInfo");
    if (partnerInfo) {
      setPartnerInfo(JSON.parse(partnerInfo));
    }
    const step = localStorage.getItem("step");
    if (step) {
      setStep(Number(step));
    }
  }, []);

  // Save data to local storage...
  useEffect(() => {
    if (partnerInfo && step) {
      localStorage.setItem("partnerInfo", JSON.stringify(partnerInfo));
      localStorage.setItem("step", step.toString());
    }
  }, [partnerInfo, step]);

  // Handle reset data...
  const handleReset = () => {
    // Reset partner info...
    setPartnerInfo({
      name: "",
      avatar: "",
      description: "",
      email: "",
      pro: false,
      uid: "",
      password: "",
      country: {
        slug: "ke",
        name: "Kenya",
        code: "+254",
      },
      address: {
        street: "",
        city: "",
        state: "",
      },
      category: {
        id: "",
        name: "",
        sub_categories: [],
      },
      contact: {
        email: "",
        phone: "",
      },
    });
    // Reset step...
    setStep(1);
  };

  // Check if user has an account...
  const handleCheckUser = async (email: string) => {
    try {
      // Set loading...
      swal({
        content: (
          <div className="swal-loader-inner">
            <TailSpin height={30} width={30} />
            <p style={{ marginTop: 15 }}>Please Wait</p>
          </div>
        ),
        closeOnClickOutside: false,
        buttons: false,
      });
      const tokenRes = await api.checkUser(email);
      if (tokenRes.ok && tokenRes.data.token) {
        // Signup user...
        const user = await api.firebaseSignup({
          email: partnerInfo.email,
          password: partnerInfo.password,
        });
        if (user && user.email) {
          swal({
            title: "Success",
            text: "Your account has been created successfully",
            icon: "success",
            closeOnClickOutside: false,
            button: "Sign In",
          }).then(() => {
            // Redirect to login...
            navigate("/");
            // Clear local storage...
            localStorage.clear();
            // Clear state...
            handleReset();
          });
        } else {
          swal({
            title: "Sorry",
            text: "Account not created, please try again",
            icon: "warning",
            button: "Got it",
          });
        }
      } else {
        // Proceed to create a partner profile...
        swal.close();
        setStep(step + 1);
      }
    } catch (error) {
      throw error;
    }
  };

  // Handle step 1...
  const handleStep1 = (event: any) => {
    event.preventDefault();
    const { email, password, confirmPassword } = event.target.elements;
    if (password.value === confirmPassword.value) {
      setPartnerInfo({
        ...partnerInfo,
        email: email.value,
        password: password.value,
      });
      // Check if user has an account...
      handleCheckUser(email.value)
        .then(() => {
          // Reset form...
          event.target.reset();
        })
        .catch((error) => {
          // Error...
          swal({
            title: "Sorry",
            text:
              error.code === "auth/email-already-in-use"
                ? "Email already in use, please try another email"
                : "Account not created, please try again",
            icon: "warning",
            button: "Sure",
          });
        });
    } else {
      setInputErrors({
        ...inputErrors,
        password: {
          show: true,
          value: "Passwords do not match",
        },
      });
    }
  };

  // Handle step 2...
  const handleStep2 = (event: any) => {
    event.preventDefault();
    const { name, phone, contactEmail } = event.target.elements;
    setPartnerInfo((prev) => {
      return {
        ...prev,
        name: name.value,
        country: selectedCountry,
        contact: {
          email: contactEmail.value,
          phone: phone.value,
        },
      };
    });
    setStep(step + 1);
  };

  // Handle step 3...
  const handleStep3 = (event: any) => {
    event.preventDefault();
    const { subCategory } = event.target.elements;
    setPartnerInfo((prev) => {
      return {
        ...prev,
        category: {
          id: selectedCategory.id,
          name: selectedCategory.name,
          sub_categories: [subCategory.value],
        },
      };
    });
    setStep(step + 1);
  };

  // Render...
  return (
    <section className="app-auth-page">
      <section className="app-auth-content">
        <section
          style={
            step === 1
              ? {
                  display: "none",
                }
              : {}
          }
          className="app-stepper-container">
          <div className="app-stepper-top">
            <div
              className={
                step === 2 || step === 3 || step === 4
                  ? "app-stepper-line-selected app-stepper-line"
                  : "app-stepper-line"
              }></div>
            <div
              className={
                step === 3 || step === 4
                  ? "app-stepper-line-selected app-stepper-line"
                  : "app-stepper-line"
              }></div>
            <div
              className={
                step === 4
                  ? "app-stepper-line-selected app-stepper-line"
                  : "app-stepper-line"
              }></div>
          </div>
          <div className="app-stepper-bottom">
            {step === 1
              ? "About"
              : step === 2
              ? "About / Contact"
              : step === 3
              ? "About / Contact / Category"
              : step === 4
              ? "About / Contact / Category / Summary"
              : ""}
          </div>
        </section>
        {step === 1 ? (
          <form
            className="app-auth-form"
            onSubmit={handleStep1}
            autoComplete="off">
            <div className="app-auth-title">Partner Sign up</div>
            <div className="app-auth-form-input-field">
              <label htmlFor="email">Email Address</label>
              <input
                name="email"
                type="text"
                required={true}
                placeholder="Email Address"
                id="email"
                className="app-input"
                onChange={(event: any) => {
                  const value = event.target.value;
                  // Validate email...
                  const valid = validateEmail(value);
                  if (!valid && value.length > 0) {
                    setInputErrors((prev) => {
                      return {
                        ...prev,
                        email: {
                          show: true,
                          value: "Invalid email address",
                        },
                      };
                    });
                  } else {
                    setInputErrors({
                      ...inputErrors,
                      email: {
                        show: false,
                        value: "",
                      },
                    });
                    setPartnerInfo((prev) => {
                      return {
                        ...prev,
                        email: value,
                      };
                    });
                  }
                  // Set email...
                  setPartnerInfo((prev) => {
                    return {
                      ...prev,
                      email: value,
                    };
                  });
                }}
                value={partnerInfo.email}
              />
              {inputErrors.email.show ? (
                <div className="app-auth-form-input-field-error">
                  {inputErrors.email.value}
                </div>
              ) : null}
            </div>
            <div className="app-auth-form-input-field">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                type="password"
                required={true}
                placeholder="Password"
                id="password"
                className="app-input"
                minLength={8}
                onChange={(event: any) => {
                  const value = event.target.value;
                  // Validate password...
                  const valid = validatePassword(value);
                  if (!valid && value.length > 0) {
                    setInputErrors((prev) => {
                      return {
                        ...prev,
                        password: {
                          show: true,
                          value:
                            "Password must be at least 8 characters, 1 uppercase, lowercase, number and special character",
                        },
                      };
                    });
                  } else {
                    setInputErrors({
                      ...inputErrors,
                      password: {
                        show: false,
                        value: "",
                      },
                    });
                    setPartnerInfo((prev) => {
                      return {
                        ...prev,
                        password: value,
                      };
                    });
                  }
                }}
              />
              {inputErrors.password.show ? (
                <div className="app-auth-form-input-field-error">
                  {inputErrors.password.value}
                </div>
              ) : null}
            </div>
            <div className="app-auth-form-input-field">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                id="confirm-password"
                autoComplete="on"
                required={true}
                className="app-input"
                minLength={8}
              />
            </div>
            <button type="submit" className="app-button">
              Proceed
            </button>
            <div className="app-auth-join-link">
              <span>Have an account?</span> <Link to="/">Sign in</Link>
            </div>
          </form>
        ) : step === 2 ? (
          <form
            className="app-auth-form"
            onSubmit={handleStep2}
            autoComplete="off">
            <div className="app-auth-form-input-field">
              <label htmlFor="name">Partner Name</label>
              <input
                type="text"
                name="name"
                placeholder="E.g Naivas Supermarket"
                id="name"
                minLength={4}
                maxLength={14}
                required={true}
                className="app-input"
                value={partnerInfo.name || ""}
                onChange={(event: any) => {
                  const value = event.target.value;
                  setPartnerInfo((prev) => {
                    return {
                      ...prev,
                      name: value,
                    };
                  });
                }}
              />
            </div>
            <div className="app-auth-form-input-field">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                name="phone"
                placeholder="E.g 0712345678"
                id="phone"
                required={true}
                className="app-input"
                onChange={(event: any) => {
                  const value = event.target.value;
                  setPartnerInfo((prev) => {
                    console.log(prev);
                    return {
                      ...prev,
                      contact: {
                        ...prev.contact,
                        phone: value,
                      },
                    };
                  });
                }}
                value={partnerInfo.contact.phone || ""}
              />
            </div>
            <div className="app-auth-form-input-field">
              <label htmlFor="contact-email">Contact Email</label>
              <input
                type="email"
                name="contactEmail"
                placeholder="E.g info@company.com"
                id="contact-email"
                required={true}
                className="app-input"
                onChange={(event: any) => {
                  const value = event.target.value;
                  setPartnerInfo((prev) => {
                    return {
                      ...prev,
                      contact: {
                        ...prev.contact,
                        email: value,
                      },
                    };
                  });
                  // Validate email...
                  const valid = validateEmail(value);
                  if (!valid && value.length > 0) {
                    setInputErrors((prev) => {
                      return {
                        ...prev,
                        contactEmail: {
                          show: true,
                          value: "Invalid email address",
                        },
                      };
                    });
                  } else {
                    setInputErrors({
                      ...inputErrors,
                      contactEmail: {
                        show: false,
                        value: "",
                      },
                    });
                  }
                }}
                // value={partnerInfo.contact.email}
              />
              {inputErrors.contactEmail.show ? (
                <div className="app-auth-form-input-field-error">
                  {inputErrors.contactEmail.value}
                </div>
              ) : null}
            </div>
            <div className="app-auth-form-input-field">
              <label htmlFor="country">Select Country</label>
              <select
                className="app-input app-auth-form-select"
                name="country"
                id="country">
                <option value="" disabled={true}>
                  Select Country
                </option>
                {countries.map(
                  (country: {
                    code: string;
                    currency: string;
                    id: string;
                    name: string;
                    slug: string;
                  }) => (
                    <option key={country.id} value={country.slug}>
                      {country.name}
                    </option>
                  )
                )}
              </select>
            </div>
            <section className="app-auth-app-stepper-buttons ">
              <button
                type="button"
                className="app-button"
                id="back-button"
                onClick={() => {
                  setStep(1);
                }}>
                Back
              </button>
              <button type="submit" className="app-button" id="next-button">
                Next
              </button>
            </section>
          </form>
        ) : step === 3 ? (
          <form
            className="app-auth-form"
            onSubmit={handleStep3}
            autoComplete="off">
            <section className="form-container">
              <div className="app-auth-form-input-field">
                <label htmlFor="category">Select Category</label>
                <select
                  onChange={(event) => {
                    // Set selected category...
                    const found = categories.find(
                      (category) => category.name === event.target.value
                    );
                    // Set selected category...
                    setSelectedCategory(found);
                  }}
                  className="app-input app-auth-form-select"
                  name="category"
                  id="catgeory"
                  value={selectedCategory ? selectedCategory.name : ""}>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="app-auth-form-input-field">
                <label htmlFor="sub-category">Sub Category</label>
                <select
                  onChange={() => {}}
                  className="app-input app-auth-form-select"
                  name="subCategory"
                  id="sub-categpry">
                  {selectedCategory.sub_categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </section>
            <section className="app-auth-app-stepper-buttons">
              <button
                type="button"
                className="app-button"
                onClick={() => {
                  setStep(2);
                }}>
                Back
              </button>
              <button type="submit" className="app-button">
                Complete
              </button>
            </section>
          </form>
        ) : (
          <section className="app-auth-summary">
            <div className="app-auth-summary-content">
              <div className="app-partner-info-auth-title">About Partner</div>
              <div>
                <p>Name : {partnerInfo.name}</p>
                <p>
                  Category :{" "}
                  {`(${partnerInfo.category.name}, ${partnerInfo.category.sub_categories[0]})`}
                </p>
                <p>Country : {partnerInfo.country.name}</p>
              </div>
              <div className="app-partner-info-auth-title">Contact Details</div>
              <p>Email : {partnerInfo.email}</p>
              <p>Phone : {partnerInfo.contact.phone}</p>
            </div>
            <section className="app-auth-app-stepper-buttons">
              <button
                className="app-button"
                onClick={() => {
                  setStep(3);
                }}>
                Back
              </button>
              <button
                className="app-button"
                onClick={async (event) => {
                  try {
                    // Prevent default...
                    event.preventDefault();
                    swal({
                      content: (
                        <div className="swal-loader-inner">
                          <TailSpin height={30} width={30} />
                          <p style={{ marginTop: 15 }}>Please Wait</p>
                        </div>
                      ),
                      closeOnClickOutside: false,
                      buttons: false,
                    });
                    // Save to local storage...
                    localStorage.setItem(
                      "partnerInfo",
                      JSON.stringify(partnerInfo)
                    );
                    // Signup user...
                    const user = await api.firebaseSignup({
                      email: partnerInfo.email,
                      password: partnerInfo.password,
                    });
                    if (user && user.email) {
                      api
                        .partnerSignup({
                          ...partnerInfo,
                          uid: user.uid,
                        })
                        .then((res) => {
                          if (res.ok) {
                            swal({
                              title: "Success",
                              text: "Your account has been created",
                              icon: "success",
                              closeOnClickOutside: false,
                              button: "Next",
                            }).then(() => {
                              // Redirect to login...
                              navigate("/");
                              // Clear local storage...
                              localStorage.clear();
                              // Clear state...
                              handleReset();
                            });
                          } else {
                            swal({
                              title: "Failed",
                              icon: "warning",
                              text: res.message,
                              button: "Got it",
                            }).then(() => {
                              // Redirect to login...
                              navigate("/");
                              // Clear local storage...
                              localStorage.clear();
                              // Clear state...
                              handleReset();
                            });
                          }
                        })
                        .catch((error) => {
                          // Error...
                          swal({
                            title: "Failed",
                            icon: "warning",
                          });
                        });
                      // Singout user...
                      api.signOut();
                    } else {
                      swal({
                        title: "Signup Failed",
                        icon: "warning",
                        text: "Please try again",
                        button: "Got it",
                      });
                    }
                  } catch (error) {
                    swal({
                      title: "Failed",
                      icon: "warning",
                      text: error.message ? error.message : "Faled to signup",
                    });
                  }
                }}>
                Complete
              </button>
            </section>
          </section>
        )}
      </section>
    </section>
  );
};

export default SignupPage;
