import { XIcon } from "@primer/octicons-react";
import React, { useContext, useEffect, useState } from "react";
import swal from "@sweetalert/with-react";
import {
  GroupedButtons,
  Header,
  LineLoader,
  PageLoader,
  Sidebar,
  Table,
  ThinBanner,
} from "../../components";
import { AuthContext } from "../../contexts";
import {
  api,
  auth,
  validateEmail,
  updateProfile,
  updateEmail,
  updatePassword,
  validatePassword,
  validateObject,
} from "../../modules";
import "./settings.css";

// Interfaces...
interface SettingsProps {
  disabled?: boolean;
  type: string;
  label: string;
  handleSave?: (id: string | number, value: any) => void;
  id: string | number;
  value?: string;
  labels?: string[];
  options?: any[];
  required?: boolean;
  admin?: boolean;
  standalone?: boolean;
  parent?: string;
}

// Settings Item component...
const SettingsItem = ({
  disabled = false,
  type = "text",
  label = "Label",
  labels = [],
  options = [],
  id = "",
  handleSave = (id: string | number, value: any) => {},
  standalone = false,
  parent = "",
  required = false,
  admin = false,
  value = "",
}: SettingsProps) => {
  // State...
  const [showLabel, setShowLabel] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const [newValue, setNewValue] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Update the value...
  useEffect(() => {
    if (value) {
      setNewValue(value);
    } else {
      setNewValue("");
    }
  }, [value]);
  // Set the selected categories...
  useEffect(() => {
    if (labels && labels.length > 0) {
      setSelectedCategories(labels);
    }
  }, [labels]);

  // Allow edit if standalone...
  useEffect(() => {
    if (standalone) {
      setAllowEdit(false);
    } else {
      setAllowEdit(true);
    }
  }, [standalone]);

  // Handle Edit...
  const handleEdit = (event: any) => {
    event.preventDefault();
    setAllowEdit(false);
  };
  // Handle Cancel...
  const handleCancel = (event: any) => {
    event.preventDefault();
    setAllowEdit(true);
  };

  // Render...
  return (
    <div className="app-settings-content-item">
      <div className="app-settings-content-input-field">
        {showLabel || newValue.length > 1 || type === "label" ? (
          <label className="app-settings-content-input-label">{label}</label>
        ) : (
          ""
        )}
        <div
          style={
            type === "label" && selectedCategories.length > 0
              ? {}
              : { display: "none" }
          }
          className="app-settings-content-input-options">
          {selectedCategories.map((categ) => (
            <div
              key={categ.toLowerCase()}
              className="app-settings-content-input-options-item">
              <span className="app-settings-content-input-options-item-label">
                {categ}
              </span>
              <span
                className="app-settings-content-input-options-item-remove"
                onClick={(e) => {
                  e.preventDefault();
                  if (admin) {
                    const newCategories = selectedCategories.filter(
                      (c) => c !== categ
                    );
                    setSelectedCategories(newCategories);
                    // const payload
                    const label = standalone ? id : parent;
                    const value = standalone
                      ? newCategories
                      : { [id]: newCategories };
                    handleSave(label, value);
                    setAllowEdit(true);
                  }
                }}>
                <XIcon />{" "}
              </span>
            </div>
          ))}
        </div>
        {type === "label" ? (
          <select
            disabled={admin ? false : true}
            className="app-settings-content-input"
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              if (admin) {
                const list = selectedCategories;
                // Check if the value is already in the list...
                if (list.indexOf(e.target.value) === -1) {
                  list.push(e.target.value);
                  setNewValue("");
                }
                // Update the state...
                setSelectedCategories(list);
                // Handle the save...
                const label = standalone ? id : parent;
                const value = standalone ? list : { [id]: list };
                handleSave(label, value);
                setAllowEdit(true);
                setShowLabel(true);
              }
            }}
            required={required}>
            {options.map((label: string, index: number) => (
              <option key={index} value={label}>
                {label}
              </option>
            ))}
          </select>
        ) : (
          <input
            autoComplete="false"
            className="app-settings-content-input"
            type={type}
            placeholder={label}
            value={newValue}
            onChange={(event) => {
              if (event.target.value.length > 0) {
                setNewValue(event.target.value);
                setShowLabel(true);
              } else {
                setNewValue("");
                setShowLabel(false);
              }
            }}
            disabled={disabled || allowEdit}
            required={required}
          />
        )}
      </div>
      {disabled ? (
        ""
      ) : (
        <div className="app-settings-content-input-buttons">
          <button
            style={allowEdit ? { display: "none" } : {}}
            type="button"
            className="app-button app-settings-button"
            onClick={(event) => {
              // Prevent default...
              event.preventDefault();
              // Save...
              const label = standalone ? id : parent;
              const value = standalone ? newValue : { [id]: newValue };
              handleSave(label, value);
              setAllowEdit(true);
            }}>
            Save
          </button>
          <button
            style={allowEdit ? { display: "none" } : {}}
            type="button"
            className="app-button app-button-cancel app-settings-button"
            onClick={handleCancel}>
            Cancel
          </button>
          <button
            style={allowEdit ? {} : { display: "none" }}
            type="button"
            className="app-button app-button-cancel app-settings-button"
            onClick={handleEdit}>
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

const Settings = () => {
  // Context...
  const { partner, authToken, setPartner } = useContext(AuthContext);
  // State..
  const [showLineLoader, setShowLineLoader] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [currentSubCategories, setCurrentSubCategories] = useState([]);
  const [showInfo, setShowInfo] = useState({
    show: false,
    message: "",
  });
  const [userList, setUserList] = useState([]);
  const [label, setLabel] = useState("general");
  const [userRole, setUserRole] = useState("teller");

  // Set page title...
  useEffect(() => {
    // Set page title...
    document.title = "Vouchpal - Settings";
    if (partner) {
      // Show page loader...
      setShowLineLoader(true);
      // Set user list...
      setUserList(partner.users ? partner.users : []);
      // Set current sub categories...
      setCurrentSubCategories(
        partner.sub_categories ? partner.sub_categories : []
      );
      // Get categories...
      api
        .getCategories()
        .then((res) => {
          // Set categories...
          if (res.ok) {
            // Get sub categories...
            const category = res.data.find(
              (categ: any) => categ.name === partner.category.name
            );
            // Set sub categories...
            if (category) {
              setSubCategories(category.sub_categories);
            } else {
              setSubCategories([]);
            }
          }
          setShowLineLoader(false);
        })
        .catch(() => {
          // Set categories...
          setSubCategories([]);
          setShowLineLoader(false);
        });

      // Validate the bank details...
      const showAlert = validateObject(partner.bank);
      if (showAlert) {
        setShowInfo({
          show: true,
          message: "Please update your bank details to continue",
        });
      }
    }

    // Cleanup...
    return () => {
      // Show page loader...
      setShowLineLoader(false);
      setSubCategories([]);
    };
  }, [partner]);

  // Handle setting save...
  const handleSettingSave = (label: string, value: string) => {
    // Show the loader...
    setShowLineLoader(true);
    const payload = { [label]: value };
    // Update the partner...
    api
      .updatePartner(partner.short_id, authToken, payload)
      .then((res) => {
        if (res.ok) {
          // Set the partner...
          setPartner((prev: any) => {
            return {
              ...prev,
              ...res.data.partner,
            };
          });
          // Show swal...
          swal({
            title: "Success",
            text: "Your settings have been updated",
            icon: "success",
            button: "Got it",
          });
        } else {
          // Show swal...
          swal({
            title: "Sorry",
            text: "Something went wrong",
            icon: "warning",
            button: "Got it",
          });
          // Set loading...
          setShowLineLoader(false);
        }
      })
      .catch((error) => {
        // Error...
        // Show swal...
        swal({
          title: "Sorry",
          text: "Something went wrong",
          icon: "warning",
          button: "Got it",
        });
        // Set loading...
        setShowLineLoader(false);
      });
  };

  // Handle user role change...
  const handleUserRoleChange = (event: any) => {
    setUserRole(event.target.value);
  };

  // Handle add user...
  const handleAddUser = (_label: string, value: string) => {
    const user = {
      email: value,
      role: userRole,
    };
    // Validate email...
    if (validateEmail(user.email)) {
      // Show the loader...
      setShowLineLoader(true);
      // Add user...
      if (userList.find((item) => item.email === user.email)) {
        // Show swal...
        swal({
          title: "Sorry",
          text: "User already exists",
          icon: "warning",
          button: "Got it",
        });
        // Set loading...
        setShowLineLoader(false);
      } else {
        api
          .addUser(partner.short_id, authToken, user)
          .then((res) => {
            if (res.ok) {
              // Show swal...
              swal({
                title: "Success",
                text: "User added to the partner account successfully",
                icon: "success",
                button: "Got it",
              }).then(() => {
                // Set loading...
                setShowLineLoader(false);
                // Add to user list...
                window.location.reload();
              });
            } else {
              // Show swal...
              swal({
                title: "Sorry",
                text: "Something went wrong",
                icon: "warning",
                button: "Got it",
              });
              // Set loading...
              setShowLineLoader(false);
            }
          })
          .catch((error) => {
            // Error...
            console.log(error);
            // Show swal...
            swal({
              title: "Sorry",
              text: "Something went wrong",
              icon: "warning",
              button: "Got it",
            });
            // Set loading...
            setShowLineLoader(false);
          });
      }
    }
  };

  // Handle remove user...
  const handleRemoveUser = (user: { email: string; role: string }) => {
    // Show the loader...
    setShowLineLoader(true);
    // Remove user...
    api
      .removeUser(partner.short_id, authToken, user)
      .then((res) => {
        if (res.ok) {
          // Show swal...
          swal({
            title: "Success",
            text: "User has been successfully removed",
            icon: "success",
            button: "Got it",
          }).then(() => {
            // Set loading...
            setShowLineLoader(false);
            // Remove from user list...
            window.location.reload();
          });
        } else {
          // Show swal...
          swal({
            title: "Sorry",
            text: res.message,
            icon: "warning",
            button: "Got it",
          });
          // Set loading...
          setShowLineLoader(false);
        }
      })
      .catch((error) => {
        // Show swal...
        swal({
          title: "Sorry",
          text: "Something went wrong",
          icon: "warning",
          button: "Got it",
        });
        // Set loading...
        setShowLineLoader(false);
      });
  };

  // Update user profile...
  const handleUpdateUserProfile = (item: string, value: string) => {
    // Show the loader...
    setShowLineLoader(true);
    // Switch...
    switch (item) {
      case "name":
        updateProfile(auth.currentUser, {
          displayName: value,
        })
          .then(() => {
            // Show swal...
            swal({
              title: "Success",
              text: "Profile has been updated successfully",
              icon: "success",
              button: "Got it",
            });
            // Set loading...
            setShowLineLoader(false);
          })
          .catch((error) => {
            swal({
              title: "Sorry",
              text:
                error && error.message
                  ? error.message
                  : "Failed to update profile, please try again",
              icon: "warning",
              button: "Got it",
            });
            // Set loading...
            setShowLineLoader(false);
          });
        break;
      case "email":
        updateEmail(auth.currentUser, "user@example.com")
          .then(() => {
            // Show swal...
            swal({
              title: "Success",
              text: "Profile has been updated successfully",
              icon: "success",
              button: "Got it",
            });
            // Set loading...
            setShowLineLoader(false);
          })
          .catch((error) => {
            // Show swal...
            swal({
              title: "Sorry",
              text:
                error && error.message
                  ? error.message
                  : "Failed to update profile, please try again",
              icon: "warning",
              button: "Got it",
            });
            // Set loading...
            setShowLineLoader(false);
          });
        break;
      case "password":
        updatePassword(auth.currentUser, value)
          .then(() => {
            // Show swal...
            swal({
              title: "Success",
              text: "Profile has been updated successfully",
              icon: "success",
              button: "Got it",
            });
          })
          .catch((error) => {
            // Show swal...
            swal({
              title: "Sorry",
              text:
                error && error.message
                  ? error.message
                  : "Failed to update profile, please try again",
              icon: "warning",
              button: "Got it",
            });
            // Set loading...
            setShowLineLoader(false);
          });
        break;
      default:
        break;
    }
  };

  // Render...
  return (
    <section className="app-page">
      <LineLoader show={showLineLoader} />
      <PageLoader show={showLineLoader} />
      <Sidebar />
      <section className="app-page-content app-settings">
        <Header />
        <ThinBanner
          show={showInfo.show}
          message={showInfo.message}
          type="warning"
          allowClose={false}
        />
        <section className="app-settings-content">
          <h1 className="app-page-title">Settings</h1>
          <section className="app-settings-grouped-buttons">
            <GroupedButtons
              clickHandler={(value) => {
                // Label...
                setLabel(value);
              }}
              buttons={[
                {
                  label: "General",
                },
                {
                  label: partner.admin ? "Manage Users" : "Users",
                },
                {
                  label: "My Profile",
                },
              ]}
            />
          </section>
          {label === "general" ? (
            <section className="app-settings-group">
              <section className="app-settings-item">
                <div className="app-settings-content-subtitle">
                  Partner Details
                </div>
                <section className="app-settings-content-section">
                  {[
                    {
                      id: "name",
                      type: "text",
                      label: "Partner Name",
                      value: partner.name ? partner.name : "",
                      disabled: true,
                      standalone: true,
                    },
                    {
                      id: "email",
                      type: "email",
                      label: "Partner Email",
                      value: partner.email ? partner.email : "",
                      disabled: true,
                      standalone: false,
                      parent: "contact",
                    },
                    {
                      id: "phone",
                      type: "tel",
                      label: "Partner Phone",
                      value: partner.contact ? partner.contact.phone : "",
                      disabled: false,
                      standalone: false,
                      parent: "contact",
                    },
                    {
                      id: "category",
                      type: "text",
                      label: "Partner Category",
                      value: partner.category ? partner.category.name : "",
                      disabled: true,
                      standalone: true,
                    },
                    {
                      id: "sub_categories",
                      type: "label",
                      label: "Partner Sub-categories",
                      value: partner.sub_categories
                        ? partner.sub_categories[0]
                        : "",
                      labels: currentSubCategories,
                      options: subCategories,
                      disabled: true,
                      admin: partner.admin,
                      standalone: true,
                    },
                    {
                      id: "country",
                      type: "text",
                      label: "Partner Country",
                      value: partner.country ? partner.country.name : "",
                      disabled: true,
                      standalone: true,
                    },
                    {
                      id: "street",
                      type: "text",
                      label: "Address Street",
                      value: partner.address ? partner.address.street : "",
                      disabled: false,
                      standalone: false,
                      parent: "address",
                    },
                  ].map(
                    (
                      item: {
                        label: string;
                        disabled: boolean;
                        type: string;
                        id: string | number;
                        value: string;
                        labels?: string[];
                        options?: any[];
                        admin?: boolean;
                        standalone?: boolean;
                        parent?: string;
                      },
                      index: number
                    ) => (
                      <SettingsItem
                        key={`${index}-${item.label}`}
                        id={item.id}
                        label={item.label}
                        type={item.type}
                        value={item.value}
                        disabled={partner.admin ? item.disabled : true}
                        labels={item.labels}
                        options={item.options}
                        handleSave={handleSettingSave}
                        admin={item.admin}
                        standalone={item.standalone}
                        parent={item.parent}
                      />
                    )
                  )}
                </section>
              </section>
              <section className="app-settings-item">
                <div className="app-settings-content-subtitle">
                  Bank Details
                </div>
                <section className="app-settings-content-section">
                  {[
                    {
                      id: "bank_name",
                      type: "text",
                      label: "Bank Name",
                      value: partner.bank.bank_name
                        ? partner.bank.bank_name
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                    {
                      id: "bank_code",
                      type: "text",
                      label: "Bank Code",
                      value: partner.bank.bank_code
                        ? partner.bank.bank_code
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                    {
                      id: "account_number",
                      type: "text",
                      label: "Bank Account Number",
                      value: partner.bank.account_number
                        ? partner.bank.account_number
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                    {
                      id: "account_name",
                      type: "text",
                      label: "Bank Account Name",
                      value: partner.bank.account_name
                        ? partner.bank.account_name
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                    {
                      id: "swift_code",
                      type: "text",
                      label: "Bank Swift Code",
                      value: partner.bank.swift_code
                        ? partner.bank.swift_code
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                    {
                      id: "account_type",
                      type: "text",
                      label: "Bank Account Type",
                      value: partner.bank.account_type
                        ? partner.bank.account_type
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                    {
                      id: "branch_name",
                      type: "text",
                      label: "Bank Account Branch",
                      value: partner.bank.branch_name
                        ? partner.bank.branch_name
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                    {
                      id: "branch_code",
                      type: "text",
                      label: "Bank Account Branch Code",
                      value: partner.bank.branch_code
                        ? partner.bank.branch_code
                        : "",
                      disabled: false,
                      standalone: false,
                      parent: "bank",
                    },
                  ].map(
                    (
                      item: {
                        label: string;
                        disabled: boolean;
                        type: string;
                        id: string | number;
                        value: any;
                        standalone?: boolean;
                        parent?: string;
                      },
                      index: number
                    ) => (
                      <SettingsItem
                        key={`${index}-${item.label}`}
                        id={item.id}
                        label={item.label}
                        type={item.type}
                        value={item.value}
                        disabled={partner.admin ? item.disabled : true}
                        handleSave={handleSettingSave}
                        standalone={item.standalone}
                        parent={item.parent}
                      />
                    )
                  )}
                </section>
              </section>
            </section>
          ) : label === "manage-users" || label === "users" ? (
            <section className="app-settings-group">
              <section className="app-settings-item">
                <section className="app-settings-content-section">
                  {partner.admin ? (
                    <section>
                      <div className="app-settings-content-section-header">
                        Add New User
                      </div>
                      <section className="app-settings-content-form">
                        <div className="app-settings-content-form-item">
                          <label className="app-settings-content-form-header">
                            Select Role
                          </label>
                          <div className="app-settings-content-form-checkbox">
                            <input
                              name="teller"
                              id="teller"
                              type="checkbox"
                              value="teller"
                              onChange={handleUserRoleChange}
                              checked={userRole === "teller" ? true : false}
                            />
                            <label htmlFor="teller">Teller</label>
                          </div>
                          <div className="app-settings-content-form-checkbox">
                            <input
                              name="admin"
                              id="admin"
                              type="checkbox"
                              value="admin"
                              onChange={handleUserRoleChange}
                              checked={userRole === "admin" ? true : false}
                            />
                            <label htmlFor="admin">Admin</label>
                          </div>
                        </div>
                        <SettingsItem
                          id="email"
                          label="Email"
                          type="email"
                          handleSave={handleAddUser}
                          standalone={true}
                        />
                      </section>
                    </section>
                  ) : null}
                  <div className="app-settings-content-section-header">
                    Current Users
                  </div>
                  <section className="app-settings-content-section-users">
                    <Table
                      header={[
                        {
                          label: "User Email",
                        },
                        {
                          label: "User Role",
                        },
                        {
                          label: "Manage User",
                        },
                      ]}
                      data={userList.map((user, index) => (
                        <tr key={`${index}-${user.email}`}>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            <button
                              disabled={partner.admin ? false : true}
                              onClick={() => handleRemoveUser(user)}
                              className="app-button app-button-delete app-button-small">
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    />
                  </section>
                </section>
              </section>
            </section>
          ) : label === "my-profile" ? (
            <section className="app-settings-group">
              <section className="app-settings-item">
                <div className="app-settings-content-subtitle">My Profile</div>
                <section className="app-settings-content-section">
                  <SettingsItem
                    id="name"
                    label="Your Name"
                    type="text"
                    handleSave={(_id, value) => {
                      handleUpdateUserProfile("name", value);
                    }}
                    standalone={true}
                    value={
                      auth.currentUser.displayName
                        ? auth.currentUser.displayName
                        : ""
                    }
                  />
                  <SettingsItem
                    id="email"
                    label="Your Email"
                    type="email"
                    handleSave={(_id, value) => {
                      handleUpdateUserProfile("email", value);
                    }}
                    standalone={true}
                    value={auth.currentUser.email ? auth.currentUser.email : ""}
                    disabled={true}
                  />
                  <SettingsItem
                    id="password"
                    label="New Password"
                    type="password"
                    standalone={true}
                    handleSave={(_id, value) => {
                      // Validate password...
                      const valid = validatePassword(value);
                      if (valid) {
                        handleUpdateUserProfile("password", value);
                      } else {
                        swal({
                          title: "Sorry",
                          text: "Password must be at least 8 characters long",
                          icon: "warning",
                          button: "Got it",
                        });
                      }
                    }}
                  />
                </section>
              </section>
            </section>
          ) : null}
        </section>
      </section>
    </section>
  );
};

export default Settings;
