import { PlusIcon } from "@primer/octicons-react";
import React, { useContext, useEffect, useState } from "react";
import swal from "@sweetalert/with-react";
import { TailSpin } from "react-loader-spinner";
import {
  GroupedButtons,
  Header,
  Modal,
  Sidebar,
  Table,
} from "../../components";
import { AuthContext } from "../../contexts";
import { Wallet as PartnerWallet } from "../../models";
import { api, validateObject } from "../../modules";
import "./wallet.css";
import { useNavigate } from "react-router-dom";

const Wallet = () => {
  // Context...
  const { partner, authToken } = useContext(AuthContext);
  // Router...
  const navigate = useNavigate();
  // State...
  const [requests, setRequests] = useState([]);
  const [selected, setSelected] = useState("completed");
  const [completed, setCompleted] = useState([]);
  const [approved, setApproved] = useState([]);
  const [pending, setPending] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [fetchingTransactions, setFetchingTransactions] = useState(false);
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [fetchingWallet, setFetchingWallet] = useState(false);
  const [wallet, setWallet] = useState<PartnerWallet>(null);
  const [canRequest, setCanRequest] = useState(false);

  // Set page title...
  useEffect(() => {
    document.title = "Vouchpal - Wallet";
  }, []);

  // Get partner payout requests...
  useEffect(() => {
    // Get requests...
    if (partner && partner.admin) {
      if (authToken) {
        // Get requests...
        setFetchingTransactions(true);
        api
          .getPartnerPayoutRequests(partner.short_id, authToken)
          .then((res) => {
            if (res) {
              // Get rejected requests...
              const rejectedRequests = res.filter(
                (item: { status: string }) => item.status === "rejected"
              );
              // Get pending requests...
              const pendingRequests = res.filter(
                (item: { status: string }) => item.status === "pending"
              );
              // Get approved requests...
              const approvedRequests = res.filter(
                (item: { status: string }) => item.status === "approved"
              );
              // Get completed requests...
              const completedRequests = res.filter(
                (item: { status: string }) => item.status === "completed"
              );
              // Update state...
              setRejected(rejectedRequests);
              setApproved(approvedRequests);
              setPending(pendingRequests);
              setCompleted(completedRequests);
              setRequests(completedRequests);
              setFetchingTransactions(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setFetchingTransactions(false);
          });
        // Get wallet...
        setFetchingWallet(true);
        api
          .getPartnerWallet(partner.short_id, authToken)
          .then((res) => {
            if (res) {
              setWallet(res);
            }
            setFetchingWallet(false);
          })
          .catch((error) => {
            console.log(error);
            setFetchingWallet(false);
          });
      } else {
        // Redirect to login...
        navigate("/");
      }
      // Validate if user can request payout...
      const invalid = validateObject(partner.bank);
      setCanRequest(invalid ? false : true);
    } else {
      // Redirect to login...
      navigate("/");
    }
    // Cleanup...
    return () => {
      setRejected([]);
      setApproved([]);
      setPending([]);
      setCompleted([]);
      setRequests([]);
      setWallet(null);
      setFetchingTransactions(false);
      setFetchingWallet(false);
    };
  }, [partner, authToken, navigate]);

  // Set data to display...
  useEffect(() => {
    // Set data to display...
    switch (selected) {
      case "completed":
        setRequests(completed);
        break;
      case "approved":
        setRequests(approved);
        break;
      case "pending":
        setRequests(pending);
        break;
      case "rejected":
        setRequests(rejected);
        break;
      default:
        setRequests([]);
    }
  }, [selected, completed, approved, pending, rejected]);

  // Handle payout request...
  const handlePayoutRequest = (event: any) => {
    event.preventDefault();
    const { amount } = event.target;
    if (partner && authToken && amount.value) {
      const amountValue = Number(amount.value);
      // Hide modal...
      setShowPayoutModal(false);
      // Check that no pending requests exist...
      if (canRequest) {
        if (pending.length === 0 && amountValue <= wallet.balance) {
          swal({
            content: (
              <div className="swal-loader-inner">
                <TailSpin height={30} width={30} />
                <p style={{ marginTop: 15 }}>Please Wait</p>
              </div>
            ),
            closeOnClickOutside: false,
            buttons: false,
          });
          api
            .requestPayout(partner.short_id, authToken, {
              amount: amountValue,
            })
            .then((res) => {
              if (res.ok) {
                swal({
                  title: "Success",
                  text: "Payout request sent successfully",
                  icon: "success",
                  closeOnClickOutside: false,
                  button: "Got it",
                }).then(() => {
                  setShowPayoutModal(false);
                  api
                    .getPartnerPayoutRequests(partner.short_id, authToken)
                    .then((res) => {
                      if (res) {
                        // Get rejected requests...
                        const rejectedRequests = res.filter(
                          (item: { status: string }) =>
                            item.status === "rejected"
                        );
                        // Get pending requests...
                        const pendingRequests = res.filter(
                          (item: { status: string }) =>
                            item.status === "pending"
                        );
                        // Get completed requests...
                        const completedRequests = res.filter(
                          (item: { status: string }) =>
                            item.status === "completed"
                        );
                        // Update state...
                        setRejected(rejectedRequests);
                        setPending(pendingRequests);
                        setCompleted(completedRequests);
                        setRequests(completedRequests);
                        setFetchingTransactions(false);
                      }
                    })
                    .catch((err) => {
                      setFetchingTransactions(false);
                    });
                });
              } else {
                swal({
                  title: "Failed",
                  text: res.message,
                  icon: "warning",
                  closeOnClickOutside: false,
                  button: "Got it",
                }).then(() => {
                  setShowPayoutModal(true);
                });
              }
            })
            .catch(() => {
              swal({
                title: "Failed",
                text: "An error occurred while processing your request.",
                icon: "warning",
                closeOnClickOutside: false,
                button: "Got it",
              }).then(() => {
                setShowPayoutModal(true);
              });
            });
        } else {
          swal({
            title: "Failed",
            text: partner.admin
              ? amountValue > wallet.balance
                ? "You have insufficient funds in your wallet to make this request"
                : "You have pending payout requests. Please wait for them to be processed"
              : "You are not authorized to request payouts",
            icon: "warning",
            closeOnClickOutside: false,
            button: "Got it",
          }).then(() => {
            setShowPayoutModal(true);
          });
        }
      } else {
        swal({
          title: "Failed",
          text: "Please update your bank details to request payouts",
          buttons: ["Cancel", "Settings"],
        }).then((res: boolean) => {
          if (res) {
            navigate("/settings");
          }
        });
      }
    }
  };

  // Render...
  return (
    <section className="app-page">
      <Sidebar />
      <section className="app-page-content app-dashboard">
        <Header />
        <section className="app-wallet-content">
          <h1 className="app-page-title">Settlements</h1>
          <section className="app-wallet-balance">
            {fetchingWallet ? (
              <div className="app-wallet-balance-placeholer app-pulse"></div>
            ) : (
              <div className="app-wallet-balance-title">
                Balance:{" "}
                <span className="app-wallet-balance-amount">
                  {wallet && wallet.balance
                    ? `${wallet.currency.toLocaleUpperCase()} ${wallet.balance.toLocaleString()}`
                    : "KES 0.00"}
                </span>
              </div>
            )}
          </section>
          <section className="app-wallet-content-inner">
            {/* <div className="app-page-subtitle">Payouts</div> */}
            <section className="app-wallet-content-ctas">
              <GroupedButtons
                className="app-wallet-grouped-buttons"
                clickHandler={(value) => {
                  setSelected(value.toLocaleLowerCase());
                }}
                buttons={[
                  {
                    label: "Pending",
                  },
                  {
                    label: "Approved",
                  },
                  {
                    label: "Completed",
                  },
                  {
                    label: "Rejected",
                  },
                ]}
              />
              <button
                className="app-button"
                onClick={() => {
                  setShowPayoutModal(true);
                }}>
                <PlusIcon /> <span>New Request</span>
              </button>
            </section>
            <section className="app-table-container">
              <Table
                header={[
                  {
                    label: "Requested by",
                    width: 150,
                  },
                  {
                    label: "Amount (Ksh)",
                    width: 175,
                  },
                  {
                    label: "Date & Time",
                    width: 150,
                  },
                  {
                    label: "Account",
                    width: 100,
                  },
                  {
                    label: "Bank Details",
                    width: 150,
                  },
                  {
                    label: "Status",
                    width: 100,
                  },
                ]}
                data={requests.map((item) => (
                  <tr key={item.id}>
                    <td className="app-wallet-table-requested-by">
                      {item.requested_by.email}
                    </td>
                    <td className="app-wallet-table-amount">
                      {item.amount.toLocaleString()}
                    </td>
                    <td className="app-wallet-table-date">
                      <div className="app-wallet-table-date-date">
                        {item.date_created.created_on}
                      </div>
                      <div className="app-wallet-table-date-time">
                        {item.date_created.time_since}
                      </div>
                    </td>
                    <td className="app-wallet-table-account">
                      <div className="app-wallet-table-date-date">
                        {item.account_name}
                      </div>
                      <div className="app-wallet-table-date-time">
                        {item.account_number}
                      </div>
                    </td>
                    <td className="app-wallet-table-bank">
                      <div className="app-wallet-table-date-date">
                        {item.bank_name}
                      </div>
                      <div className="app-wallet-table-date-time">
                        {item.bank_code}
                      </div>
                    </td>
                    <td className="app-wallet-table-status">
                      <span
                        className={
                          item.status === "successful" ||
                          item.status === "completed"
                            ? "app-label app-label-success"
                            : item.status === "approved"
                            ? "app-label app-label-waiting"
                            : item.status === "failed" ||
                              item.status === "rejected"
                            ? "app-label app-label-error"
                            : "app-label app-label-warning"
                        }>
                        <span>{item.status}</span>
                      </span>
                    </td>
                  </tr>
                ))}
                emptyText={
                  fetchingTransactions
                    ? "Fetching data..."
                    : "No transactions yet"
                }
              />
            </section>
          </section>
        </section>
      </section>
      <Modal
        title="Request Payout"
        showModal={showPayoutModal}
        onClose={() => {
          setShowPayoutModal(false);
        }}
        modalContent={
          <form
            autoComplete="off"
            autoCorrect="off"
            className="app-process-token-form"
            onSubmit={handlePayoutRequest}>
            <div className="app-modal-form-input">
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                id="amount"
                name="amount"
                min={100}
                required
              />
            </div>
            <button className="app-button app-button-primary app-button-disabled">
              Send
            </button>
          </form>
        }
      />
    </section>
  );
};

export default Wallet;
